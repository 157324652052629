import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-m7tsb325v6bckk3z.us.auth0.com"
      clientId="SaocqzCAm10iCqkjazHlLU01diCOlfyn"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://api-dev.kuru.la'
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
