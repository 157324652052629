import "./App.css";
import React, { useState, useEffect } from "react";
import { NavBarButtons } from "./components/nav-bar-buttons";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/loading";
import Profile from "./components/profile";
import { jwtDecode } from "jwt-decode";

function App() {
  const [token, setToken] = useState("");
  const [companyId, setCompanyId] = useState("");

  const [purchaseInput, setPurchaseInput] = useState({
    card: -1,
    merchant: "",
    amount: "1000",
    currency: "ARS",
  });

  const [cardHolders, setCardHolders] = useState([]);
  const [cardHolderId, setCardHolderId] = useState("");
  const [pomeloUserId, setPomeloUserId] = useState("");

  const [status, setStatus] = useState("");

  const [cards, setCards] = useState([]);
  const [isKuruAdmin, setIsKuruAdmin] = useState(false);
  const [companies, setCompanies] = useState([]);

  const merchants = {
    Garbarino: {
      id: "111111111111111",
      mcc: "5045",
      address: null,
      name: "Home Appliances",
    },
    McDonalds: {
      id: "222222222222222",
      mcc: "5046",
      address: null,
      name: "Fast Food",
    },
    Steam: {
      id: "333333333333333",
      mcc: "5047",
      address: null,
      name: "Videogames",
    },
    YPF: {
      id: "44444444444444",
      mcc: "7538",
      address: null,
      name: "Combustible",
    },
    Starbucks: {
      id: "5555555555555",
      mcc: "5969",
      address: null,
      name: "Almuerzo",
    },
  };

  const apiUrlPrefix = "https://api.dev.kuru.la";

  const handleCardholderChange = async (event) => {
    const value = event.target.value;
    console.log("valueCardHolderChange", value);
    const userIds = value.split("|");
    const aCardHolderId = userIds[0];
    const aPomeloUserId = userIds[1];
    console.log("cardHolderId: " + aCardHolderId);
    console.log("pomeloUserId: " + aPomeloUserId);

    if (!aCardHolderId) {
      setCardHolderId("");
      setCards([]);
      return;
    }

    setCardHolderId(aCardHolderId);
    setPomeloUserId(aPomeloUserId);
    setCards([]);

    setPurchaseInput({
      card: -1,
      merchant: "",
      amount: "1000",
      currency: "ARS",
    });

    setStatus(`Fetching cards for selected user...`);
    const response = await fetch(
      `${apiUrlPrefix}/companies/${companyId}/card-holders/${aCardHolderId}/cards`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    console.log(responseData);

    if (!responseData.cards || responseData.cards.length === 0) {
      setStatus("No Cards.");
      return;
    }

    setCards(responseData.cards);

    setStatus(`Cards OK (${responseData.cards.length})`);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPurchaseInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchCards = async () => {
    const response = await fetch(
      `${apiUrlPrefix}/companies/${companyId}/card-holders/${cardHolderId}/cards`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    console.log(responseData);

    if (!responseData.cards || responseData.cards.length === 0) {
      setStatus("No Cards.");
      return;
    }

    setCards(responseData.cards);
  };

  const purchase = async () => {
    console.log(purchaseInput);
    setStatus(`Processing purchase...`);

    const uuid = crypto.randomUUID();

    const card = cards[purchaseInput.card];

    const payload = {
      transaction: {
        id: `ctx-${uuid}`,
        country_code: "ARG",
        type: "PURCHASE",
        point_type: "ECOMMERCE",
        entry_mode: "MANUAL",
        origin: merchants[purchaseInput.merchant].origin || "DOMESTIC",
        local_date_time: new Date().toISOString(),
        original_transaction_id: null,
      },
      merchant: merchants[purchaseInput.merchant],
      card: {
        id: card.pomeloId,
        product_type: "PREPAID",
        provider: "MASTERCARD",
        last_four: card.lastFour,
      },
      user: {
        id: pomeloUserId,
      },
      amount: {
        local: {
          total: purchaseInput.amount,
          currency: purchaseInput.currency,
        },
        transaction: {
          total: purchaseInput.amount,
          currency: purchaseInput.currency,
        },
        settlement: {
          total: purchaseInput.amount / 1000,
          currency: "USD",
        },
        details: [
          {
            type: "BASE",
            currency: purchaseInput.currency,
            amount: purchaseInput.amount,
            name: "BASE",
          },
        ],
      },
    };

    console.log(payload);

    const fakerUrlPrefix = "https://faker.dev.kuru.la";

    const response = await fetch(`${fakerUrlPrefix}/internal/authorizations`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-idempotency-key": crypto.randomUUID(),
      },
      method: "POST",
      body: JSON.stringify(payload),
    });

    console.log(response);

    if (response.status === 200) {
      const responseData = await response.json();
      setStatus(
        `${responseData.status} (${responseData.status_detail}): ${responseData.message}`
      );

      await fetchCards();
    } else {
      setStatus(`error (${response.status})`);
    }
  };

  const handleCompanyChange = async (event) => {
    const selectedCompanyId = event.target.value;
    if (!selectedCompanyId) {
      setCardHolders([]);
      setCardHolderId("");
      setCards([]);
      setStatus("No Company selected.");
      return;
    }
    setCompanyId(selectedCompanyId);
    setStatus(`Fetching card holders for selected company...`);

    const response = await fetch(
      `${apiUrlPrefix}/companies/${selectedCompanyId}/card-holders`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();
    if (responseData.cardHolders) {
      setCardHolders(responseData.cardHolders);
      setStatus("CardHolders OK");
    } else {
      setCardHolders([]);
      setStatus("No cardholders found.");
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const fetchCompanies = async () => {
      try {
        setStatus("Fetching companies...");
        const aToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://api-dev.kuru.la`,
          },
        });
        setToken(aToken);
        console.log(aToken);

        const decodedToken = jwtDecode(aToken);
        console.log(decodedToken);

        setIsKuruAdmin(decodedToken.is_kuru_admin || false);
        console.log("isKuruAdmin", decodedToken.is_kuru_admin);

        if (decodedToken.is_kuru_admin) {
          const companiesResponse = await fetch(
            `${apiUrlPrefix}/companies`,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
            }
          );
          const companiesData = await companiesResponse.json();
          console.log("companiesData", companiesData);
          setCompanies(companiesData.companies);
          setStatus("Companies OK");
        } else {
          setStatus("No tiene permisos para acceder a esta función.");
        }
      } catch (error) {
        console.error(error.message);
        console.log("catch error");
        setStatus(error.message);
      }
    };

    fetchCompanies();
  }, [
    getAccessTokenSilently,
    setStatus,
    setCardHolders,
    setToken,
    setCompanyId,
    isLoading,
  ]);

  if (isLoading) {
    return (
      <div className="App">
        <header className="App-header">
          <Loading />
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <Profile />
        <NavBarButtons />
        <h1>Kuru Fake POS</h1>
        <span>{status}</span>
        {isAuthenticated &&
          (isKuruAdmin ? (
            <form onSubmit={handleSubmit}>
              <div>
                <label>Compañía:</label>
                <select
                  id="company"
                  name="company"
                  value={companyId}
                  onChange={handleCompanyChange}
                >
                  <option value="">Select...</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>cardHolder:</label>
                <select
                  id="cardHolder"
                  name="cardHolder"
                  value={purchaseInput.cardHolder}
                  onChange={handleCardholderChange}
                >
                  <option value="">Select...</option>
                  {cardHolders.map((cardHolder) => {
                    return (
                      <option
                        key={cardHolder.id + "|" + cardHolder.pomeloUserId}
                        value={cardHolder.id + "|" + cardHolder.pomeloUserId}
                      >
                        {cardHolder.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div>
                <label>Card:</label>
                <select
                  id="card"
                  name="card"
                  value={purchaseInput.card}
                  onChange={handleChange}
                >
                  <option value="">Select...</option>
                  {cards.map((card, index) => {
                    return (
                      <option key={card.pomeloId} value={index}>
                        ...{card.lastFour} "{card.alias}" (${card.balance})
                      </option>
                    );
                  })}
                </select>
              </div>

              <div>
                <label>Merchant:</label>
                <select
                  id="merchant"
                  name="merchant"
                  value={purchaseInput.merchant}
                  onChange={handleChange}
                >
                  <option value="">Select...</option>
                  <option value="Garbarino">Garbarino</option>
                  <option value="Steam">Steam</option>
                  <option value="McDonalds">McDonalds</option>
                  <option value="McDonalds">YPF</option>
                  <option value="McDonalds">Starbucks</option>
                </select>
              </div>

              <div>
                <label>Amount:</label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  value={purchaseInput.amount}
                  onChange={handleChange}
                ></input>
              </div>

              <div>
                <label>Currency:</label>
                <select
                  id="currency"
                  name="currency"
                  value={purchaseInput.currency}
                  onChange={handleChange}
                >
                  <option value="">Select...</option>
                  <option value="ARS">ARS</option>
                  <option value="USD">USD</option>
                </select>
              </div>

              <button type="submit" onClick={purchase}>
                Comprar
              </button>
            </form>
          ) : null)}
      </header>
    </div>
  );
}

export default App;
